/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'
import Loading from 'vue-loading-overlay'
import 'aos/dist/aos.css'
import router from './router'
import store from './store'
import Argon from './plugins/argon-kit'
import VueAnalytics from 'vue-analytics'
import VueMatchHeights from 'vue-match-heights'
import VueCoreVideoPlayer from 'vue-core-video-player'

import './registerServiceWorker'
import * as filters from './filters' // global filters

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

global.axios = require('axios')

Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== 'production'
Vue.use(Argon)
Vue.use(Loading)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMatchHeights)
Vue.use(VueCoreVideoPlayer)
Vue.use(VueAnalytics, {
  id: 'UA-180776447-1',
  router
})
new Vue({
  router,
  store,
  created () {
    AOS.init({
      easing: 'ease',
      duration: 1000
    })
  },
  render: h => h(App)
}).$mount('#app')
