import { ASSETS_PATH, POST_ASSETS_PATH } from '@/.env'
export function imgsrc (src) {
  return ASSETS_PATH + src
}
export function postimgsrc (src) {
  return POST_ASSETS_PATH + src
}
/**
 *
 * @param {*} string
 *
 * ? String converted into Capital
 */
export function capitalize (string) {
  return string.toUpperCase()
}
