<template>
  <div>
    <section class="section-acupuncture section-shaped my-0"
      :style="{'background-image': 'url(' + $options.filters.imgsrc('img/accupuncture.jpg') + ')'}">
      <div class="shape shape-style-1 shape-primary">
        <span class="span-150"></span>
        <span class="span-50"></span>
        <span class="span-50"></span>
        <span class="span-75"></span>
        <span class="span-100"></span>
        <span class="span-75"></span>
        <span class="span-50"></span>
        <span class="span-100"></span>
        <span class="span-50"></span>
        <span class="span-100"></span>
      </div>
      <div class="container shape-container d-flex align-items-center">
        <div class="col px-0">
          <div class="row justify-content-center align-items-center text-center">
            <div class="col-lg-6">
              <h1 class="display-1 text-white">Acupuncture</h1>
              <p class="lead text-white">
                Traditional Chinese acupuncture, Dry needling and traditional Japanese acupuncture</p>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section my-5 p-0">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-12">
            <h2 class="display-3 text-white">Acupunture overview</h2>
            <p class="lead text-center text-white">Acupuncture is a treatment that involves inserting
                very thin needles at specific points on the body, to various
                depths.<br>Acupuncture has been safely used for thousands of years to treat a variety of medical complaints successfully. <br>
            It is one of the eight limbs of Chinese medicine and arguably one of the most powerful. <br>
            It involves the insertion of very fine needles into points along meridians (energy pathways). <br>
            The acupuncture points used are carefully chosen according to Chinese medicine theory. Like other practitioners, Acupuncturists ask questions and take patients' medical histories, but where we differ is the use of many diagnostic procedures specific to Chinese medicine.<br>
            <br>
            An Acupuncturist uses meridian palpation, abdominal palpation, tongue and pulse diagnosis to select specific acupuncture points known to create the desired effect. These diagnostic skills take many years to master.<br>
            <br>
The skilled Acupuncturist with correct point selection can strengthen organs, balance the body's complex energy system and directly impact the nervous system.<br>
            Acupuncture effectively treats a broad range of symptoms, many that are not well treated by the western medical system, and  without nasty side effects from toxic medications. It is also beneficial for building the immune system, strengthening the convalescing patient, pre-empting and preventing complex disease patterns. Moreover, it is fantastic for reducing stress levels fast.</p>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-4 py-5">
            <card class="border-0  first-row-cards" hover shadow body-classes="p-0 text-center">
              <img v-lazy="$options.filters.imgsrc('img/accupuncture-1.jpg')" alt="PMS Hormonal Imbalance"
                class="card-img-top" />

              <h6 class="text-dark font-weight-bold mt-2">INJURY, ACCIDENT, MUSCLE AND JOINT PAIN
              </h6>
              <p class="description p-3 text-left mt-3">Acupuncture is probably best known for dealing with
                structural painful conditions of the body (Musculo- skeletal
                system). It is fast and efficient therapy for dealing with:</p>
              <ul class="mt-3 text-left">
                <li>
                  Sports Injuries
                </li>
                <li>
                  Motor Accidents
                </li>
                <li>
                  Neck Injuries/Pain
                </li>
                <li>
                  Back Problems
                </li>
                <li>
                  Ankle/ Knee Pain-Instability
                </li>
                <li>
                  Shoulder Issues
                </li>
                <li>
                  Broken bones
                </li>
                <li>
                  Swelling and bruising
                </li>
                <li>
                  Growing pains
                </li>
                <li>
                  Speeding up recovery after surgery
                </li>
              </ul>
              <p class="description p-3 text-left mt-3">Other therapies may be included in the treatment of the
                above conditions such as: Herbal Medicines, Yoga Therapy and Physical Therapy</p>
            </card>
          </div>
          <div class="col-md-4 py-5">
            <card class="border-0  first-row-cards" hover shadow body-classes="p-0 text-center">
              <img v-lazy="$options.filters.imgsrc('img/accupuncture-2.jpg')" alt="PMS Hormonal Imbalance"
                class="card-img-top" />

              <h6 class="text-dark font-weight-bold mt-2">WOMENS HORMONAL ISSUES</h6>
              <p class="description p-3 text-left mt-3">Natural Therapies and particularly Acupuncture and Herbal
                Medicines are very effective when it comes to regulating female hormones. Over two
                decades Michelle has accumulated a vast amount of experience and success in the
                following areas:</p>
              <ul class="mt-3 text-left">
                <li>
                  Menstrual pain / Irregularity
                </li>
                <li>
                  PMT
                </li>
                <li>
                  PCOS
                </li>
                <li>
                  Infertility
                </li>
                <li>
                  Menopausal symptoms
                </li>
                <li>
                  &amp; the whole gamete of hormonal dysfunction.
                </li>
              </ul>

            </card>
          </div>
          <div class="col-md-4 py-5">
            <card class="border-0  first-row-cards" hover shadow body-classes="p-0 text-center">
              <img v-lazy="$options.filters.imgsrc('img/accupuncture-3.jpg')"
              alt="PMS Hormonal Imbalance"
                class="card-img-top" />

              <h6 class="text-dark font-weight-bold mt-2">HEADACHES, MIGRAINES AND PAIN</h6>
              <p class="description p-3 text-left mt-3">Traditional Chinese Acupuncture is simply second to none
                when it comes to eliviating headaches and migraines. <br>
                If you arrive at your
                appointment in pain we can guarantee relief within minutes after Acupuncture
                treatment has been administered. <br><br>

                Dealing with the root cause of the pain can be more
                involved and typically requires a minimum of six acupuncture sessions on a weekly
                basis</p>
            </card>
          </div>
          <div class="col-md-4 py-5">
            <card class="border-0 second-row-cards" hover shadow body-classes="p-0 text-center">
              <img v-lazy="$options.filters.imgsrc('img/accupuncture-4.jpg')"
              alt="PMS Hormonal Imbalance"
                class="card-img-top" />

              <h6 class="text-dark font-weight-bold mt-2">FATIGUE</h6>
              <p class="description p-3 text-left mt-3">Fatigue is perhaps the most common complaint of modern
                times. Treatment protocols and the therapies perscribed depend on what lies beneath
                the symptom of fatigue, this of course varies from one individual to another. Great
                results can be achieved with lifestyle modification, stress reduction, Herbal
                supplements, Nutrition and Acupuncture .</p>
            </card>
          </div>
          <div class="col-md-4 py-5">
            <card class="border-0 second-row-cards" hover shadow body-classes="p-0 text-center">
              <img v-lazy="$options.filters.imgsrc('img/accupuncture-5.jpg')"
              alt="PMS Hormonal Imbalance"
                class="card-img-top" />

              <h6 class="text-dark font-weight-bold mt-2">DIGESTIVE ISSUES</h6>
              <p class="description p-3 text-left mt-3">We offer evidence based Natural Treatment for:</p>
              <ul class="mt-3 text-left">
                <li>
                  Parasites
                </li>
                <li>
                  Candida
                </li>
                <li>
                  Gut Disbiosis
                </li>
                <li>
                  Leaky Gut
                </li>
                <li>
                  Malabsorption syndrome
                </li>
                <li>
                  Bloating
                </li>
                <li>
                  Pain
                </li>
                <li>
                  Reflux
                </li>
                <li>
                  Constipation / Diarrhoea
                </li>
                <li>
                  IBS / Chrones
                </li>
                <li>
                  Gut detoxification and repair programs
                </li>
              </ul>
              <p class="description p-3 text-left mt-3"> Natural Therapies, particularly Herbal and Nutritional
                Prescriptions produce consistently solid results in the treatment of the above
                conditions. Individual diagnosis is required to prescribe the correct treatment
                strategy as the "root" cause of digestive issues is different for each individual.
              </p>
            </card>
          </div>
          <div class="col-md-4 py-5">
            <card class="border-0  second-row-cards" hover shadow body-classes="p-0 text-center">
              <img v-lazy="$options.filters.imgsrc('img/accupuncture-6.jpg')"
              alt="PMS Hormonal Imbalance"
                class="card-img-top" />

              <h6 class="text-dark font-weight-bold mt-2">MOOD DISORDERS, ANXIETY AND INSOMNIA</h6>
              <!-- <p class="description p-3 text-left mt-3">We offer evidence based Natural Treatment for:</p> -->
              <ul class="mt-3 text-left">
                <li>
                  Depression
                </li>
                <li>
                  Anxiety
                </li>
                <li>
                  Stress
                </li>
                <li>
                  Insomnia
                </li>
                <li>
                  "Looping" thoughts
                </li>
                <li>
                  "Wired" feeling
                </li>
              </ul>
              <p class="description p-3 text-left mt-3"> Western medicines treatment of mood disorders is based on
                the ancient greek assumption that mind and body are separate. This paradigm has
                never existed in Chinese Medicine or any other Traditional Medicine for that matter:
                Rather the wellness or dis-ease of the mind or body are seen as the expression of
                the quality and flow of Qi (energy) thus treating mood disorders is no different
                than treating any other condition using the Traditional Chinese Medical Model.
              </p>
              <p class="description p-3 text-left mt-3">
                Moreover, recent studies in the fields of methylation, pyroles, heavy metals and the
                “gut micro-biome” have given natural medicine practitioners new tools to treat these
                conditions successfully. </p>
            </card>
          </div>

        </div>
        <div class="row row-grid align-items-center justify-content-center">
          <div class="col-md-12">
            <tabs fill class="flex-column flex-md-row text-black">
              <card shadow>
                <tab-pane title="What it's used for">
                  <p class="description">Conditions commonly treated with acupuncture::</p>
                  <ul>
                    <li>Headaches, migraines and general Pain.</li>
<li>Pre-menstrual Syndrome.</li>
<li>Period Pain.</li>
<li>Infertility.</li>
<li>Menopause.</li>
<li>Irregular periods.</li>
<li>Hormonal Imbalance.</li>
<li>Irritable Bowel Syndrome</li>
<li>Reflux.</li>
<li>Weak digestion and bloating</li>
<li>Constipation and diarrhea</li>
<li>Addiction</li>
<li>Anxiety and Depression</li>
<li>Sleep disorders/ Insomnia</li>
<li>Colds/Flu</li>
<li>Lung Infections</li>
<li>Sinusitis</li>
<li>Skin disorders, acne, hives, psoriasis</li>
<li>Pregnancy</li>
<li>Joint swelling, inflammation and pain.</li>
<li>Sports Injuries</li>
                  </ul>

                </tab-pane>

                <tab-pane title="Risks">
                  <p class="description">The risks of acupuncture are minimal with a competent, certified acupuncture practitioner using sterile needles. Common minor side effects include soreness and minor bleeding or bruising where the needles were inserted.<br>
                  Single-use, disposable needles are the practice standard, so the risk of infection is negligible.<br>
                  You may be at risk of complications if you:</p>
                  <ul>
                    <li><strong>Have a bleeding disorder.</strong> Your chances of bleeding or bruising from the needles increase if you have a bleeding disorder. Most of the time there is no bleeding from Acupuncture but you must let the practitioner know if you have any bleeding disorders so they can choose another style of acupuncture that is less intrusive.</li>
                    <li><strong>Have a pacemaker.</strong>As long as you inform your acupuncturist this is not a problem. The only possible issue is if your practitioner uses Acupuncture that involves applying mild electrical pulses to the needles. Electrical acupuncture can interfere with a pacemaker's operation. This is easily avoided.</li>
                    <li><strong>Are pregnant.</strong> Acupuncture is commonly used to treat many of the complaints of pregnancy. However it is essential you let your Acupuncturist know if you are or think you many be preganant as some acupuncture points are thought to stimulate the Uterus causing miscarriage or labour. Again this is easily avoided through skilled selection of acupuncture points.</li>
                  </ul>
                </tab-pane>

                <tab-pane title="Benefits">
                  <p class="description">Acupuncture can be beneficial in that:</p>
                  <ul>
                    <li>Performed correctly, it is safe.</li>
                    <li>There are very few side effects.</li>
                    <li>It can be effectively combined with other WESTERN OR ALTERNATIVE treatments.</li>
<li>It can control MANY types of pain unresponsive to other forms of treatment.</li>
<li>It may help patients for whom pain medications are not suitable, or do not want to use pain medications long term.</li>
<li>For individuals who want to treat the root cause of disease and not focus on treating symptoms.</li>
<li>For individuals who do not tolerate medications.</li>
<li>Preventing illness </li>
<li>Stress reduction</li>
                  </ul>
                  <p class="description">The NCCIH advise people not to use acupuncture instead of
                    seeing a conventional health care provider.</p>
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped overflow-hidden">
      <div class="shape bg-gradient-warning">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-3 text-center">
            <div class="mb-5 mb-lg-0">
              <icon name="ni ni-calendar-grid-58" size="lg" color="black" class="icon-appointment">
              </icon>
            </div>
          </div>
          <div class="col-lg-6 border-right border-dark">
            <div class="mb-5 mb-lg-0">
              <h2>
                Request an Appointment
              </h2>
              <p class="mt-4">
                We are available for in-clinic and virtual appointments five days per week.<br>
Appointments times are available from 7.30 am to 4pm Bali time.<br>
The clinic is located on the popular Batu Bolong street, Canggu 100m from the beach.<br>
              </p>
            </div>
          </div>
          <div class="col-lg-3 text-center">
            <div class="mb-5 mb-lg-0">
              <base-button data-aos="zoom-in" tag="a" href="/contact" type="black"
                icon="ni ni-curved-next" class="mb-3 mb-sm-0 mt-4 btn-dark">
                Book An appointment
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'acupuncture',
  components: {
    Tabs: () => import('@/components/Tabs/Tabs.vue'),
    TabPane: () => import('@/components/Tabs/TabPane.vue')
  },
  metaInfo: {
    title: 'Acupuncture'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
<style lang="scss" scoped>

</style>
