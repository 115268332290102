<template>
    <div id="app">
      <!-- <PageLoader /> -->
        <router-view name="header"></router-view>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>
    </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
// import PageLoader from './views/components/PageLoader'

export default {
  name: 'App',
  components: {
    FadeTransition
    // PageLoader
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Natural Medicine Portal',
    // all titles will be injected into this template
    titleTemplate: '%s | Natural Medicine Portal'
  }
}
</script>
