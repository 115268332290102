import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
// import AppHeader from './layout/AppHeader'
// import AppFooter from './layout/AppFooter'
// import Components from './views/Components.vue'
// import Home from './views/Home.vue'
// import Acupuncture from './views/Acupuncture.vue'
// import NaturalMedicine from './views/NaturalMedicine.vue'
// import Consults from './views/Consults.vue'
// import HowToUseAMoxaStick from './views/HowToUseAMoxaStick.vue'
// import Pms from './views/courses/Pms.vue'
// import Menopause from './views/courses/Menopause.vue'
// import Bwr from './views/courses/Bwr.vue'
// import Landing from './views/Landing.vue'
// import Login from './views/Login.vue'
// import Register from './views/Register.vue'
// import About from './views/About.vue'
// import BlogHome from './views/BlogHome.vue'
// import Forms from './views/FormsHome.vue'
// import NewPatientWomen from './views/NewPatientWomen.vue'
// import BlogPost from './views/BlogPost.vue'
// import Contact from './views/Contact.vue'
// import Profile from './views/Profile.vue'
const AppHeader = () => import(/* webpackChunkName: "header" */ './layout/AppHeader')
const AppFooter = () => import(/* webpackChunkName: "footer" */ './layout/AppFooter')
// const Components = () => import('./views/Components.vue')
const Home = () => import(/* webpackChunkName: "home" */'./views/Home.vue')
const Acupuncture = () => import(/* webpackChunkName: "acupuncture" */'./views/Acupuncture.vue')
const NaturalMedicine = () => import(/* webpackChunkName: "naturalMedicine" */'./views/NaturalMedicine.vue')
const Consults = () => import(/* webpackChunkName: "consults" */'./views/Consults.vue')
const OnlineConsultation = () => import(/* webpackChunkName: "onlineConsultation" */'./views/OnlineConsultation.vue')
const HowToUseAMoxaStick = () => import(/* webpackChunkName: "HowToUseAMoxaStick" */'./views/HowToUseAMoxaStick.vue')
const HowToUseAMoxaBox = () => import(/* webpackChunkName: "HowToUseAMoxaBox" */'./views/HowToUseAMoxaBox.vue')
const GentleDetoxificationOrganicCoffeeEnema = () => import(/* webpackChunkName: "GentleDetoxificationOrganicCoffeeEnema" */'./views/GentleDetoxificationOrganicCoffeeEnema.vue')
const BoneBrothRecipe = () => import(/* webpackChunkName: "BoneBrothRecipe" */'./views/BoneBrothRecipe.vue')
const VeggieBrothRecipe = () => import(/* webpackChunkName: "VeggieBrothRecipe" */'./views/VeggieBrothRecipe.vue')
const HowToTakeYourHerbalGranules = () => import(/* webpackChunkName: "HowToTakeYourHerbalGranules" */'./views/HowToTakeYourHerbalGranules.vue')
const Pms = () => import(/* webpackChunkName: "pms" */'./views/courses/Pms.vue')
const Menopause = () => import(/* webpackChunkName: "menopause" */'./views/courses/Menopause.vue')
const Bwr = () => import(/* webpackChunkName: "bwr" */'./views/courses/Bwr.vue')
// const Landing = () => import('./views/Landing.vue')
// const Login = () => import('./views/Login.vue')
// const Register = () => import('./views/Register.vue')
const About = () => import(/* webpackChunkName: "about" */'./views/About.vue')
const BlogHome = () => import(/* webpackChunkName: "blogHome" */'./views/BlogHome.vue')
const BlogPost = () => import(/* webpackChunkName: "blogPost" */'./views/BlogPost.vue')
const Forms = () => import(/* webpackChunkName: "formsHome" */'./views/FormsHome.vue')
const NewPatientWomen = () => import(/* webpackChunkName: "newPatientWomen" */'./views/NewPatientWomen.vue')
const NewPatientMen = () => import(/* webpackChunkName: "newPatientMen" */'./views/NewPatientMen.vue')
const NewPatientMusculoskeletal = () => import(/* webpackChunkName: "newPatientMen" */'./views/NewPatientMusculoskeletal.vue')
const Contact = () => import(/* webpackChunkName: "contact" */'./views/Contact.vue')
const Profile = () => import(/* webpackChunkName: "profile" */'./views/Profile.vue')
const NutrientRichFoods = () => import(/* webpackChunkName: "profile" */'./views/NutrientRichFoods.vue')

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: '/acupuncture',
      name: 'acupuncture',
      components: {
        header: AppHeader,
        default: Acupuncture,
        footer: AppFooter
      }
    },
    {
      path: '/natural-medicine',
      name: 'naturalmedicine',
      components: {
        header: AppHeader,
        default: NaturalMedicine,
        footer: AppFooter
      }
    },
    {
      path: '/consults',
      name: 'consults',
      components: {
        header: AppHeader,
        default: Consults,
        footer: AppFooter
      }
    },
    {
      path: '/online-consultation',
      name: 'online-consultation',
      components: {
        header: AppHeader,
        default: OnlineConsultation,
        footer: AppFooter
      }
    },
    {
      path: '/how-to-use-a-moxa-stick',
      name: 'how-to-use-a-moxa-stick',
      components: {
        header: AppHeader,
        default: HowToUseAMoxaStick,
        footer: AppFooter
      }
    },
    {
      path: '/how-to-use-a-moxa-box',
      name: 'how-to-use-a-moxa-box',
      components: {
        header: AppHeader,
        default: HowToUseAMoxaBox,
        footer: AppFooter
      }
    },
    {
      path: '/gentle-detoxification-organic-coffee-enema',
      name: 'gentle-detoxification-organic-coffee-enema',
      components: {
        header: AppHeader,
        default: GentleDetoxificationOrganicCoffeeEnema,
        footer: AppFooter
      }
    },
    {
      path: '/bone-broth-recipe',
      name: 'bone-broth-recipe',
      components: {
        header: AppHeader,
        default: BoneBrothRecipe,
        footer: AppFooter
      }
    },
    {
      path: '/veggie-broth-recipe',
      name: 'veggie-broth-recipe',
      components: {
        header: AppHeader,
        default: VeggieBrothRecipe,
        footer: AppFooter
      }
    },
    {
      path: '/how-to-take-your-herbal-granules',
      name: 'how-to-take-your-herbal-granules',
      components: {
        header: AppHeader,
        default: HowToTakeYourHerbalGranules,
        footer: AppFooter
      }
    },
    {
      path: '/courses',
      name: 'Home',
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: '/course/pms_and_period_pain_relief',
      name: 'PMS',
      components: {
        header: AppHeader,
        default: Pms,
        footer: AppFooter
      }
    },
    {
      path: '/course/menopause',
      name: 'Menopause',
      components: {
        header: AppHeader,
        default: Menopause,
        footer: AppFooter
      }
    },
    {
      path: '/course/bloating-and-water-retention',
      name: 'Bwr',
      components: {
        header: AppHeader,
        default: Bwr,
        footer: AppFooter
      }
    },
    // {
    //   path: '/landing',
    //   name: 'landing',
    //   components: {
    //     header: AppHeader,
    //     default: Landing,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: '/login',
    //   name: 'login',
    //   components: {
    //     header: AppHeader,
    //     default: Login,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: '/register',
    //   name: 'register',
    //   components: {
    //     header: AppHeader,
    //     default: Register,
    //     footer: AppFooter
    //   }
    // },
    {
      path: '/about',
      name: 'about',
      components: {
        header: AppHeader,
        default: About,
        footer: AppFooter
      }
    },
    {
      path: '/forms',
      name: 'forms',
      components: {
        header: AppHeader,
        default: Forms,
        footer: AppFooter
      }
    },
    {
      path: '/forms/new-patient-women',
      name: 'new-patient-women',
      components: {
        header: AppHeader,
        default: NewPatientWomen,
        footer: AppFooter
      }
    },
    {
      path: '/forms/new-patient-men',
      name: 'new-patient-men',
      components: {
        header: AppHeader,
        default: NewPatientMen,
        footer: AppFooter
      }
    },
    {
      path: '/forms/new-patient-musculoskeletal',
      name: 'new-patient-musculoskeletal',
      components: {
        header: AppHeader,
        default: NewPatientMusculoskeletal,
        footer: AppFooter
      }
    },
    {
      path: '/blog',
      name: 'blog',
      components: {
        header: AppHeader,
        default: BlogHome,
        footer: AppFooter
      }
    },
    {
      path: '/blog/:slug',
      name: 'blogpost',
      components: {
        header: AppHeader,
        default: BlogPost,
        footer: AppFooter
      }
    },
    {
      path: '/contact',
      name: 'contact',
      components: {
        header: AppHeader,
        default: Contact,
        footer: AppFooter
      }
    },
    {
      path: '/nutrient-rich-foods',
      name: 'nutrient-rich-foods',
      components: {
        header: AppHeader,
        default: NutrientRichFoods,
        footer: AppFooter
      }
    },
    {
      path: '/profile',
      name: 'profile',
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter
      }
    }
    // {
    //   path: '/components',
    //   name: 'components',
    //   components: {
    //     header: AppHeader,
    //     default: Components,
    //     footer: AppFooter
    //   }
    // }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})
